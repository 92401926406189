export const WORDS = [
  'bakuk',
  'celis',
  'lawar',
  'kolah',
  'karih',
  'silih',
  'jerai',
  'katah',
  'putil',
  'roboh',
  'rokok',
  'tirah',
  'titik',
  'spora',
  'capoi',
  'pilon',
  'umbun',
  'pusar',
  'putar',
  'encit',
  'dusta',
  'patam',
  'langi',
  'rejuk',
  'alkah',
  'imani',
  'Allah',
  'rakam',
  'helah',
  'lunas',
  'bandu',
  'randa',
  'debab',
  'Natal',
  'pejur',
  'tahar',
  'jimat',
  'goyah',
  'asung',
  'julat',
  'kemal',
  'asese',
  'bomba',
  'cegar',
  'tokok',
  'pasif',
  'sedih',
  'domah',
  'lapun',
  'ronda',
  'bukur',
  'sebih',
  'jeram',
  'suluk',
  'mimik',
  'kadam',
  'gerek',
  'dekad',
  'mitos',
  'totem',
  'gandi',
  'cukup',
  'tukal',
  'lapak',
  'lazim',
  'perun',
  'culik',
  'terpa',
  'katik',
  'bakuh',
  'danta',
  'jolek',
  'bidak',
  'cetak',
  'senak',
  'ancam',
  'barus',
  'jepit',
  'kernu',
  'akses',
  'mampu',
  'genus',
  'panji',
  'bidur',
  'gilik',
  'keram',
  'butik',
  'baiah',
  'turus',
  'resak',
  'tayar',
  'tabii',
  'piano',
  'apron',
  'lebum',
  'asfar',
  'jelik',
  'dasau',
  'lolen',
  'tabik',
  'kedok',
  'pecuk',
  'licin',
  'mabuk',
  'basuh',
  'sayap',
  'Yunan',
  'gipsi',
  'aulia',
  'tilan',
  'nekad',
  'mahar',
  'papan',
  'sando',
  'pulur',
  'kuari',
  'robot',
  'busat',
  'dopis',
  'nomad',
  'capak',
  'ondak',
  'kerat',
  'sohor',
  'kilai',
  'rango',
  'jalia',
  'kakap',
  'peluk',
  'kubis',
  'repek',
  'amung',
  'meter',
  'mubah',
  'getil',
  'tapih',
  'kekeh',
  'ketar',
  'ketai',
  'buhul',
  'kitar',
  'balah',
  'regan',
  'mayat',
  'halir',
  'papah',
  'konvo',
  'tiara',
  'lorot',
  'biuku',
  'kasaj',
  'mekar',
  'madat',
  'gadai',
  'meski',
  'apkir',
  'kepar',
  'rebas',
  'desik',
  'irung',
  'syekh',
  'hijau',
  'catuh',
  'gegas',
  'namun',
  'pakal',
  'jenuh',
  'jerak',
  'hasta',
  'petan',
  'rimba',
  'ragut',
  'pajak',
  'kumus',
  'fatur',
  'sebik',
  'lagak',
  'bilut',
  'wahyu',
  'jubin',
  'tanti',
  'sepah',
  'video',
  'seksa',
  'cawat',
  'marin',
  'serbi',
  'galur',
  'takah',
  'lalap',
  'sojah',
  'kahwa',
  'kesum',
  'rimpi',
  'lekar',
  'ihsan',
  'serik',
  'rewet',
  'denak',
  'pajuh',
  'watak',
  'rewak',
  'perum',
  'jemur',
  'Engku',
  'serot',
  'zapin',
  'belat',
  'tatai',
  'sapar',
  'lahan',
  'filem',
  'blurp',
  'sasak',
  'tingi',
  'embal',
  'lafal',
  'sulap',
  'kenik',
  'lejuh',
  'desut',
  'lemah',
  'kedau',
  'incar',
  'jarah',
  'degur',
  'tikus',
  'nadim',
  'undak',
  'ghain',
  'sertu',
  'lucup',
  'kudsi',
  'kartu',
  'pisau',
  'uzlah',
  'blaus',
  'kupil',
  'kisar',
  'becek',
  'rakit',
  'lubuk',
  'cemuh',
  'pacai',
  'arnab',
  'safan',
  'kasar',
  'hotel',
  'advis',
  'bocok',
  'beleh',
  'rebat',
  'belek',
  'tenar',
  'lebun',
  'ganda',
  'remet',
  'jamin',
  'boros',
  'cakup',
  'bakat',
  'balus',
  'kekam',
  'bikar',
  'litup',
  'peket',
  'butut',
  'zamin',
  'angka',
  'tapis',
  'kicap',
  'atasi',
  'tajau',
  'suari',
  'ilham',
  'dasun',
  'desis',
  'humor',
  'gamak',
  'katam',
  'redas',
  'bonet',
  'lekak',
  'loloh',
  'tuala',
  'lekap',
  'tampu',
  'rahib',
  'tulis',
  'qadim',
  'kirai',
  'bilad',
  'toyah',
  'tutor',
  'rocoh',
  'tauke',
  'jelit',
  'rodan',
  'getek',
  'rajah',
  'loleh',
  'sifat',
  'firma',
  'takup',
  'cirit',
  'hurai',
  'derik',
  'kasad',
  'deguk',
  'rodat',
  'bodoh',
  'hinap',
  'leser',
  'rebeh',
  'bagak',
  'berek',
  'sedam',
  'ambil',
  'kelis',
  'embek',
  'pepah',
  'safih',
  'erang',
  'debus',
  'borak',
  'sukun',
  'jirat',
  'jamui',
  'jerah',
  'tulur',
  'hukum',
  'altar',
  'Batak',
  'pijat',
  'bafta',
  'boroh',
  'anten',
  'umbra',
  'detik',
  'barut',
  'ketis',
  'masih',
  'salit',
  'murai',
  'lepat',
  'bidik',
  'bajul',
  'tisik',
  'badam',
  'cadik',
  'banjo',
  'sumbi',
  'suhuf',
  'tabut',
  'lukut',
  'demap',
  'banir',
  'marak',
  'giang',
  'pecat',
  'endus',
  'mabir',
  'dugas',
  'sujen',
  'boksu',
  'cecah',
  'banat',
  'tawan',
  'bagas',
  'kebek',
  'biduk',
  'donat',
  'toyol',
  'vogue',
  'tugal',
  'nazar',
  'canak',
  'baghi',
  'geram',
  'regat',
  'sadap',
  'iftar',
  'tutur',
  'hafiz',
  'kepik',
  'janin',
  'ejaan',
  'bagus',
  'petik',
  'nilur',
  'ancer',
  'perih',
  'gatra',
  'riung',
  'sedar',
  'dodos',
  'lahap',
  'rancu',
  'kekah',
  'trofi',
  'tanau',
  'bende',
  'redam',
  'eloan',
  'baras',
  'pesuk',
  'panir',
  'umpat',
  'absah',
  'layur',
  'coret',
  'deham',
  'serum',
  'palit',
  'paldu',
  'laden',
  'bihun',
  'hamba',
  'bantu',
  'bilar',
  'pohon',
  'minat',
  'ronta',
  'merih',
  'samsu',
  'joran',
  'detar',
  'reben',
  'embus',
  'talak',
  'bajik',
  'ruwin',
  'balik',
  'tapuh',
  'umbai',
  'komik',
  'genap',
  'celur',
  'ponco',
  'dirus',
  'lutut',
  'perak',
  'remak',
  'belin',
  'tekua',
  'roset',
  'retai',
  'rajuk',
  'katod',
  'pirai',
  'ketuk',
  'kadim',
  'kucil',
  'gelip',
  'talun',
  'hiasi',
  'polip',
  'bolor',
  'jelma',
  'ingau',
  'tumit',
  'solok',
  'until',
  'witir',
  'perli',
  'cabar',
  'misil',
  'bahak',
  'badak',
  'pacat',
  'cacat',
  'pesam',
  'paman',
  'piatu',
  'hebah',
  'kurap',
  'buron',
  'lelat',
  'kulum',
  'tapus',
  'unsat',
  'sukma',
  'tonik',
  'begal',
  'jurai',
  'papar',
  'zalad',
  'reput',
  'ujian',
  'pakat',
  'tinak',
  'kumut',
  'puruh',
  'recok',
  'lebah',
  'pekur',
  'junub',
  'karut',
  'kilar',
  'bonus',
  'kerut',
  'cakak',
  'rusih',
  'geran',
  'ungsi',
  'sesah',
  'ekzos',
  'decap',
  'ribut',
  'piala',
  'jahil',
  'soyak',
  'jaran',
  'Ansar',
  'remut',
  'setem',
  'resmi',
  'bubuh',
  'capil',
  'galah',
  'surah',
  'carta',
  'massa',
  'layap',
  'kotot',
  'cerun',
  'gemap',
  'rejam',
  'tikam',
  'xilem',
  'ajrih',
  'taran',
  'indik',
  'kalut',
  'arkan',
  'sarik',
  'pepes',
  'turut',
  'parpu',
  'dilam',
  'leset',
  'lebur',
  'lerum',
  'calar',
  'palis',
  'baulu',
  'rasuk',
  'tebuk',
  'rebah',
  'ustaz',
  'senat',
  'iaitu',
  'ahmar',
  'jurah',
  'sidik',
  'kasur',
  'utama',
  'antap',
  'lebuh',
  'lerak',
  'denim',
  'optik',
  'dapur',
  'lamai',
  'gayat',
  'paron',
  'pelat',
  'cegat',
  'asnad',
  'timba',
  'bauan',
  'baguh',
  'ugama',
  'penat',
  'didik',
  'kapis',
  'mamai',
  'takak',
  'ladam',
  'desup',
  'estar',
  'kerit',
  'Ambon',
  'halim',
  'pelas',
  'kusin',
  'pacal',
  'ianya',
  'hidup',
  'hadam',
  'incer',
  'gamut',
  'banar',
  'tajak',
  'randu',
  'kecup',
  'waduk',
  'hudai',
  'selat',
  'cacap',
  'pakuk',
  'mekap',
  'tekur',
  'celah',
  'lamin',
  'imlak',
  'letak',
  'nyeli',
  'hegeh',
  'gelam',
  'remai',
  'serak',
  'besin',
  'hirau',
  'rodok',
  'babat',
  'tablo',
  'paksi',
  'sebar',
  'julir',
  'sodok',
  'cepuk',
  'batih',
  'pudur',
  'tapuk',
  'punat',
  'sufal',
  'atlas',
  'peran',
  'kaman',
  'lurut',
  'litah',
  'rekan',
  'lecer',
  'bolot',
  'yakin',
  'budak',
  'galar',
  'kumat',
  'jatuh',
  'embuh',
  'afwah',
  'retih',
  'ifrad',
  'binti',
  'gemar',
  'ikuti',
  'lapan',
  'sejat',
  'batas',
  'gubir',
  'domba',
  'kudus',
  'bolen',
  'cegah',
  'omboh',
  'nazak',
  'lumus',
  'sifar',
  'bonda',
  'tempe',
  'kekek',
  'sulat',
  'kebit',
  'hafal',
  'alibi',
  'pasak',
  'putus',
  'bagis',
  'derai',
  'raket',
  'baldi',
  'cebak',
  'pepat',
  'katuk',
  'corak',
  'skirt',
  'bahan',
  'kopek',
  'biang',
  'sadah',
  'sorot',
  'jerun',
  'tinta',
  'piama',
  'lawan',
  'malam',
  'lawah',
  'demut',
  'kebas',
  'capah',
  'ketun',
  'diami',
  'porok',
  'selar',
  'genta',
  'ricih',
  'relau',
  'ragui',
  'oreng',
  'pulan',
  'pitut',
  'lumba',
  'jelar',
  'hodoh',
  'polan',
  'semah',
  'pinda',
  'kapuk',
  'gesek',
  'dukan',
  'tirak',
  'kacuk',
  'cemik',
  'kacam',
  'jamah',
  'tarak',
  'sugar',
  'hantu',
  'jidar',
  'catur',
  'tetar',
  'comel',
  'sumir',
  'kupoi',
  'sigak',
  'tanah',
  'gigis',
  'nanti',
  'embih',
  'bahwa',
  'salun',
  'legat',
  'pujur',
  'babak',
  'tatar',
  'tekuk',
  'layar',
  'logam',
  'kusut',
  'puluh',
  'kepau',
  'nguak',
  'keser',
  'demah',
  'besar',
  'taman',
  'fakir',
  'surai',
  'roman',
  'debur',
  'takal',
  'kermi',
  'jolok',
  'bokop',
  'buraq',
  'gemas',
  'temin',
  'sinki',
  'tambo',
  'genre',
  'kemit',
  'rasul',
  'lemon',
  'kutuk',
  'tawaf',
  'gauli',
  'carak',
  'kafal',
  'fokus',
  'jambu',
  'tapak',
  'datia',
  'balun',
  'gomok',
  'sisal',
  'tentu',
  'salip',
  'jerba',
  'lundu',
  'hutan',
  'semai',
  'awewe',
  'kadar',
  'tajun',
  'degam',
  'bagur',
  'omong',
  'tanam',
  'tikal',
  'aktor',
  'rempa',
  'tegur',
  'waktu',
  'isian',
  'fusta',
  'parit',
  'batik',
  'punti',
  'pilin',
  'zakar',
  'arang',
  'haris',
  'sebut',
  'jiran',
  'takar',
  'tetek',
  'bilai',
  'besit',
  'ligat',
  'belai',
  'ajari',
  'kurau',
  'gelas',
  'selia',
  'betah',
  'insya',
  'racut',
  'kisut',
  'wajar',
  'recup',
  'kepul',
  'gajus',
  'pekin',
  'pedal',
  'mujur',
  'selup',
  'sekam',
  'rakat',
  'adang',
  'banyo',
  'saran',
  'umpil',
  'lidal',
  'jarum',
  'picis',
  'wakil',
  'remuk',
  'samin',
  'kerah',
  'cubit',
  'bukti',
  'dudut',
  'nanas',
  'kesah',
  'cecar',
  'kebam',
  'tolok',
  'saham',
  'geluh',
  'saluk',
  'kasap',
  'kokok',
  'dedes',
  'jebuh',
  'subak',
  'warak',
  'dilah',
  'salju',
  'pirau',
  'gabas',
  'lalau',
  'celas',
  'gelak',
  'sagai',
  'besok',
  'puput',
  'kecar',
  'dukat',
  'sihir',
  'gajak',
  'regas',
  'hajim',
  'gumba',
  'bacar',
  'kelat',
  'cicit',
  'musuh',
  'rewan',
  'buran',
  'ketot',
  'bikin',
  'sabun',
  'wafat',
  'nanar',
  'pakam',
  'Jepun',
  'pekan',
  'medal',
  'cetus',
  'bisik',
  'hasad',
  'magel',
  'timpa',
  'mufti',
  'pacak',
  'beguk',
  'bambu',
  'suloi',
  'enjet',
  'along',
  'kejan',
  'ludah',
  'anjak',
  'debar',
  'deris',
  'karat',
  'pasar',
  'lekas',
  'anuti',
  'antih',
  'pikap',
  'bicut',
  'irasi',
  'sikai',
  'danau',
  'pakau',
  'sista',
  'dozen',
  'kedut',
  'hemah',
  'angon',
  'bawab',
  'darma',
  'pupur',
  'kayap',
  'dadih',
  'cacah',
  'asyik',
  'silir',
  'kedik',
  'sotor',
  'balut',
  'Badwi',
  'pepak',
  'lepap',
  'harbi',
  'jaing',
  'perni',
  'dekor',
  'celam',
  'badik',
  'lawak',
  'pilih',
  'letuk',
  'galai',
  'lepot',
  'tumus',
  'rumia',
  'keris',
  'peroi',
  'birit',
  'anyam',
  'fasik',
  'dunia',
  'peria',
  'kilir',
  'keran',
  'ruban',
  'morat',
  'buruj',
  'redum',
  'gasal',
  'suami',
  'Zuhal',
  'susut',
  'ruwat',
  'konon',
  'ketul',
  'getas',
  'argon',
  'banda',
  'katai',
  'angah',
  'mulut',
  'semak',
  'rocah',
  'sumin',
  'padan',
  'peruk',
  'telas',
  'sosej',
  'ekspo',
  'dalal',
  'kelim',
  'dajal',
  'lurus',
  'benar',
  'titir',
  'urung',
  'anjar',
  'konan',
  'angun',
  'pelih',
  'cicah',
  'jumud',
  'metro',
  'taung',
  'serok',
  'dicam',
  'qalam',
  'berai',
  'entek',
  'ungka',
  'repot',
  'ripai',
  'izhar',
  'mayur',
  'cepat',
  'getak',
  'siung',
  'janik',
  'tepuk',
  'celum',
  'tepek',
  'salur',
  'ampuk',
  'nujum',
  'dekah',
  'bagar',
  'pedih',
  'hukah',
  'ambah',
  'bulus',
  'asing',
  'hapak',
  'afiks',
  'togak',
  'tenam',
  'kekot',
  'belum',
  'ramai',
  'jampi',
  'setan',
  'layas',
  'kejur',
  'lakab',
  'leret',
  'pinuh',
  'pupil',
  'intil',
  'jelir',
  'botok',
  'bodok',
  'robat',
  'kepah',
  'sulah',
  'zahid',
  'unjuk',
  'royan',
  'latis',
  'ketip',
  'curah',
  'boria',
  'pipis',
  'kawad',
  'bulat',
  'satin',
  'kromo',
  'rebak',
  'citra',
  'cekup',
  'lokos',
  'rakuk',
  'imbau',
  'letih',
  'nyolo',
  'gumuk',
  'lepet',
  'gulai',
  'murah',
  'lisah',
  'dodot',
  'encer',
  'perit',
  'gilas',
  'siswa',
  'dapat',
  'kafan',
  'cemeh',
  'sesal',
  'endan',
  'tegas',
  'qadak',
  'teguk',
  'tapai',
  'canda',
  'taraf',
  'lepan',
  'ijmak',
  'tanju',
  'tokoh',
  'skema',
  'parah',
  'marga',
  'cenge',
  'tuaku',
  'fikah',
  'diapa',
  'yakut',
  'timun',
  'tabia',
  'modem',
  'tamuk',
  'miang',
  'lidah',
  'kekok',
  'carca',
  'ferum',
  'ganih',
  'serbu',
  'desah',
  'sinar',
  'seduh',
  'sekin',
  'siber',
  'suluh',
  'garau',
  'boket',
  'rawan',
  'sokak',
  'sikat',
  'bokoh',
  'cogan',
  'gagau',
  'kukur',
  'lalak',
  'pikuk',
  'rakut',
  'gomak',
  'rizab',
  'belam',
  'sinai',
  'enjin',
  'inggu',
  'jabil',
  'laser',
  'bukit',
  'batir',
  'takau',
  'bocah',
  'dayus',
  'mesti',
  'calet',
  'sudah',
  'bukau',
  'bahar',
  'bahau',
  'gadah',
  'patil',
  'detas',
  'kemih',
  'salad',
  'kapri',
  'sarap',
  'peras',
  'tutuk',
  'ceker',
  'gumam',
  'Julai',
  'cebet',
  'kecek',
  'insaf',
  'saman',
  'kebur',
  'linen',
  'jelak',
  'lesus',
  'rayah',
  'artis',
  'polok',
  'pasal',
  'rejim',
  'teral',
  'bugar',
  'bajet',
  'biksu',
  'birat',
  'lopak',
  'kanun',
  'laman',
  'lakri',
  'fardu',
  'elaun',
  'rapat',
  'Isyak',
  'gudeg',
  'mamak',
  'kabau',
  'kolam',
  'detus',
  'baran',
  'teori',
  'rindu',
  'lucut',
  'kolar',
  'Yasin',
  'baluk',
  'laksa',
  'takuk',
  'kombo',
  'fiber',
  'basit',
  'komet',
  'janji',
  'bawal',
  'cebek',
  'heboh',
  'demik',
  'lolok',
  'mohon',
  'sisik',
  'balan',
  'agung',
  'sirat',
  'bubur',
  'kekas',
  'kemab',
  'kubik',
  'ketat',
  'kuyuh',
  'sikit',
  'aplus',
  'sukai',
  'joget',
  'encik',
  'junam',
  'rahap',
  'pegar',
  'lakak',
  'pisit',
  'cedok',
  'terka',
  'tebak',
  'kucai',
  'ranah',
  'serit',
  'jobak',
  'rosok',
  'segan',
  'bincu',
  'derek',
  'mimpi',
  'tojen',
  'pedak',
  'nasib',
  'lejit',
  'nipis',
  'kembu',
  'hujah',
  'gubal',
  'nalar',
  'keler',
  'caduk',
  'terus',
  'acuan',
  'tarif',
  'kotor',
  'askar',
  'batat',
  'zikir',
  'takur',
  'anbia',
  'megah',
  'baret',
  'bendi',
  'taiko',
  'perus',
  'corot',
  'picik',
  'tajuk',
  'tabuh',
  'balok',
  'gigit',
  'penes',
  'sanuk',
  'kumuh',
  'kecam',
  'umang',
  'restu',
  'serah',
  'karah',
  'punah',
  'kaget',
  'guano',
  'pawai',
  'betas',
  'cerai',
  'kidal',
  'riket',
  'akmal',
  'pirik',
  'obrol',
  'sandi',
  'bacin',
  'jajah',
  'jahan',
  'emosi',
  'tarah',
  'cekel',
  'aping',
  'basmi',
  'tamam',
  'lagan',
  'ubang',
  'notis',
  'desus',
  'rukuk',
  'takat',
  'gerat',
  'iring',
  'mudin',
  'kambu',
  'didis',
  'garam',
  'kudup',
  'abrak',
  'ratib',
  'sasar',
  'cemak',
  'etnik',
  'betut',
  'lesap',
  'pepas',
  'dekri',
  'gecar',
  'waran',
  'lecut',
  'rasmi',
  'petak',
  'bendo',
  'karma',
  'depan',
  'batak',
  'lerai',
  'damai',
  'aerob',
  'suatu',
  'paruh',
  'sawab',
  'juang',
  'geger',
  'tegal',
  'dalam',
  'comot',
  'terik',
  'bolah',
  'selok',
  'roket',
  'ungah',
  'bobol',
  'jubal',
  'tahil',
  'kesat',
  'andal',
  'palar',
  'datar',
  'kahar',
  'capar',
  'biram',
  'geruh',
  'pedas',
  'puspa',
  'tujuh',
  'bakul',
  'benam',
  'sirih',
  'limas',
  'bacok',
  'cabak',
  'tujah',
  'teran',
  'aiwan',
  'cakal',
  'lanar',
  'pukul',
  'injak',
  'recak',
  'karas',
  'kerap',
  'seban',
  'raung',
  'gilan',
  'pusat',
  'rejah',
  'hadas',
  'kemis',
  'farad',
  'nodai',
  'salap',
  'baris',
  'akwal',
  'nakal',
  'katib',
  'pamit',
  'fasih',
  'lazat',
  'etika',
  'biola',
  'gajah',
  'carik',
  'teruk',
  'kulir',
  'gores',
  'bagul',
  'ceceh',
  'talas',
  'kakas',
  'takut',
  'gelup',
  'lulai',
  'cuban',
  'mejar',
  'calat',
  'modal',
  'relif',
  'puruk',
  'jegil',
  'sedua',
  'benge',
  'kemin',
  'pamor',
  'reban',
  'kamir',
  'Adika',
  'arbei',
  'lekup',
  'surat',
  'bedan',
  'jerat',
  'arnal',
  'bruto',
  'kumai',
  'gerak',
  'pejam',
  'kuman',
  'bonak',
  'lalat',
  'pampa',
  'kader',
  'saing',
  'kerih',
  'peluh',
  'sinus',
  'rabah',
  'layan',
  'cakah',
  'abnus',
  'pipet',
  'nobat',
  'ragas',
  'kibas',
  'leceh',
  'colok',
  'gancu',
  'gulut',
  'capal',
  'gasak',
  'bakti',
  'bunut',
  'lepau',
  'tampi',
  'letup',
  'porak',
  'gepuh',
  'warga',
  'kanji',
  'maddi',
  'Kuang',
  'wahah',
  'kelip',
  'koyok',
  'kimia',
  'Perak',
  'rutai',
  'jodoh',
  'burat',
  'tamat',
  'dogol',
  'domol',
  'gemuk',
  'angin',
  'renda',
  'takwa',
  'karau',
  'lamur',
  'debik',
  'koleh',
  'acung',
  'sebam',
  'lecup',
  'talam',
  'penak',
  'ketil',
  'ajaib',
  'panau',
  'junta',
  'tegap',
  'Malik',
  'sakar',
  'ideal',
  'binca',
  'godam',
  'pegon',
  'rekat',
  'buyut',
  'mangu',
  'sibar',
  'tenat',
  'infra',
  'pupul',
  'merah',
  'idola',
  'nilai',
  'total',
  'serta',
  'gagas',
  'rabik',
  'lodak',
  'lepar',
  'retak',
  'lecur',
  'oskar',
  'picit',
  'pamer',
  'bilis',
  'furuk',
  'bajai',
  'renik',
  'rotok',
  'anglo',
  'sesar',
  'gegap',
  'lakar',
  'sabak',
  'tabib',
  'tewas',
  'arpus',
  'jalak',
  'arena',
  'nuzul',
  'jogar',
  'lebak',
  'harap',
  'liwat',
  'jaket',
  'rajim',
  'kecoh',
  'pitis',
  'kabin',
  'iqlab',
  'laris',
  'ancuk',
  'panta',
  'huruf',
  'diris',
  'reneh',
  'nahak',
  'bedah',
  'gamis',
  'carum',
  'nonol',
  'garis',
  'sudut',
  'aluan',
  'caruk',
  'deruk',
  'muluk',
  'palak',
  'bekoi',
  'jarwa',
  'sapih',
  'desak',
  'khali',
  'nayam',
  'ramal',
  'pecal',
  'lapik',
  'jolor',
  'jebai',
  'lekit',
  'tonil',
  'gerha',
  'gugup',
  'fabel',
  'mandi',
  'lepuk',
  'kepil',
  'celak',
  'garit',
  'amsal',
  'dekam',
  'lajak',
  'tenor',
  'baada',
  'jalur',
  'daeng',
  'leduk',
  'nihil',
  'bogot',
  'jebah',
  'karun',
  'ifrit',
  'perdu',
  'tiada',
  'baloi',
  'gesel',
  'umpuk',
  'delah',
  'lesar',
  'gabus',
  'tenda',
  'habis',
  'binar',
  'boleh',
  'lobak',
  'betuk',
  'palam',
  'bogem',
  'lalah',
  'susuh',
  'wafak',
  'berak',
  'herot',
  'badal',
  'animo',
  'kesup',
  'kubah',
  'mesej',
  'kodak',
  'tenis',
  'zihar',
  'kucup',
  'lasam',
  'koyan',
  'andil',
  'hampa',
  'keruk',
  'segar',
  'bolak',
  'rogoh',
  'pokai',
  'likat',
  'delik',
  'nazam',
  'kotak',
  'pugau',
  'kelut',
  'rinai',
  'dinas',
  'busur',
  'kufur',
  'bawak',
  'selir',
  'sinyo',
  'gincu',
  'bacul',
  'gabal',
  'tunai',
  'jirim',
  'arwah',
  'kedal',
  'tiras',
  'rizom',
  'kumur',
  'sesat',
  'lesir',
  'lebas',
  'ambai',
  'yatim',
  'tekup',
  'keman',
  'kulit',
  'untuk',
  'berik',
  'ahlan',
  'aring',
  'cabuh',
  'lafaz',
  'lekeh',
  'kemap',
  'injek',
  'seram',
  'kalat',
  'bajak',
  'kepai',
  'salak',
  'kehel',
  'ridip',
  'tikai',
  'kedai',
  'derma',
  'berma',
  'palma',
  'sugul',
  'audio',
  'pelor',
  'ashab',
  'karan',
  'pelog',
  'tirai',
  'sepet',
  'sulam',
  'cikgu',
  'babil',
  'dekus',
  'kayun',
  'gapah',
  'tukam',
  'udani',
  'sijil',
  'gebuk',
  'tanji',
  'marut',
  'sikak',
  'receh',
  'gubuk',
  'lundi',
  'pecah',
  'barai',
  'sebai',
  'lorat',
  'tempa',
  'barap',
  'kandi',
  'kebab',
  'pintu',
  'aging',
  'dayah',
  'cebol',
  'cekuk',
  'lumut',
  'gurun',
  'angot',
  'tasik',
  'banit',
  'wirid',
  'balar',
  'ralat',
  'kocok',
  'gedik',
  'balas',
  'pacuk',
  'ranti',
  'rupuh',
  'teban',
  'warid',
  'susah',
  'pompa',
  'calak',
  'galau',
  'pegas',
  'catak',
  'yajuj',
  'lecus',
  'hayun',
  'logik',
  'rayau',
  'janur',
  'organ',
  'buruk',
  'gibas',
  'ibung',
  'tesis',
  'unjun',
  'rahim',
  'likur',
  'aksam',
  'tandu',
  'gajet',
  'degar',
  'cerut',
  'kesah',
  'barli',
  'rimas',
  'jolak',
  'lebap',
  'madah',
  'kebil',
  'debat',
  'gahar',
  'alwah',
  'henti',
  'emper',
  'untal',
  'laram',
  'keban',
  'lewah',
  'malim',
  'jerih',
  'Isnin',
  'amang',
  'rasai',
  'vakum',
  'sudip',
  'tetes',
  'telut',
  'radak',
  'siguh',
  'anion',
  'dadar',
  'hitam',
  'intan',
  'pelah',
  'gubra',
  'undur',
  'epsom',
  'lisol',
  'anaam',
  'pokta',
  'aruda',
  'karib',
  'lelak',
  'segah',
  'pekap',
  'rudus',
  'datin',
  'diang',
  'kabur',
  'kolon',
  'gagap',
  'ingat',
  'calui',
  'bodek',
  'tamah',
  'kelit',
  'orbit',
  'ramas',
  'gumal',
  'jumbo',
  'gelen',
  'latar',
  'dekat',
  'sawit',
  'betik',
  'ampai',
  'hisab',
  'lesau',
  'sajak',
  'fatom',
  'ragam',
  'intim',
  'acita',
  'lipur',
  'becak',
  'rubai',
  'derji',
  'momok',
  'basis',
  'lekir',
  'patut',
  'licau',
  'bebal',
  'tumpu',
  'berot',
  'andam',
  'cerap',
  'atlet',
  'kaldu',
  'kamar',
  'popia',
  'semua',
  'jelek',
  'rutin',
  'derau',
  'saron',
  'masuk',
  'iseng',
  'beruk',
  'barak',
  'sekat',
  'ummah',
  'toreh',
  'racik',
  'alusi',
  'molek',
  'usaha',
  'kapah',
  'jamak',
  'ritma',
  'libur',
  'tenuk',
  'belok',
  'ledor',
  'entah',
  'ramin',
  'kojol',
  'rerak',
  'linau',
  'bisai',
  'jalin',
  'reken',
  'bayuh',
  'ragum',
  'tabah',
  'cilik',
  'loket',
  'kecak',
  'bedak',
  'sosoh',
  'kekwa',
  'busar',
  'payah',
  'pijar',
  'manja',
  'abrar',
  'timah',
  'taram',
  'reksa',
  'maung',
  'fakta',
  'skrin',
  'reman',
  'bilal',
  'cetek',
  'lulus',
  'padam',
  'aswad',
  'gawai',
  'anyar',
  'peser',
  'kudap',
  'licik',
  'tugar',
  'tekis',
  'sayup',
  'lorek',
  'benci',
  'dogma',
  'pelan',
  'teker',
  'embun',
  'kemam',
  'bagal',
  'India',
  'sisir',
  'julur',
  'setop',
  'ginko',
  'jelai',
  'kabut',
  'pepek',
  'kurma',
  'rebuk',
  'tapal',
  'libas',
  'tuamu',
  'kaswi',
  'kahak',
  'medan',
  'barid',
  'ripuk',
  'misal',
  'pegun',
  'gomba',
  'lesih',
  'legam',
  'fizik',
  'pesta',
  'tebal',
  'tauge',
  'kejai',
  'sepak',
  'lupus',
  'todak',
  'kenja',
  'balad',
  'petia',
  'johan',
  'terau',
  'kawan',
  'ijmal',
  'belan',
  'gapil',
  'badut',
  'petua',
  'kerak',
  'tukun',
  'botak',
  'ganja',
  'cekih',
  'lewat',
  'lekah',
  'hepar',
  'curam',
  'palpa',
  'abese',
  'galak',
  'pukas',
  'silia',
  'kedul',
  'kakah',
  'nalih',
  'Israk',
  'kagum',
  'tutuh',
  'macet',
  'abjad',
  'waltz',
  'lawat',
  'tenik',
  'borek',
  'ngiau',
  'Putra',
  'pujuk',
  'panus',
  'minum',
  'palut',
  'becus',
  'telau',
  'cadai',
  'rapik',
  'tohok',
  'tamak',
  'kumis',
  'pusam',
  'purut',
  'rayan',
  'tepam',
  'macuk',
  'gobek',
  'kocak',
  'rojol',
  'suten',
  'kuyup',
  'jemba',
  'kelak',
  'serba',
  'soren',
  'kenur',
  'tujui',
  'empoh',
  'derap',
  'cabuk',
  'gagah',
  'nyiru',
  'memar',
  'bagan',
  'rotan',
  'ghaib',
  'danuh',
  'akaid',
  'tuban',
  'aprit',
  'cucuk',
  'kamus',
  'warni',
  'kuaci',
  'tumis',
  'pekup',
  'pacis',
  'pilis',
  'cekuh',
  'lisan',
  'balam',
  'bebeh',
  'rasau',
  'parau',
  'tiket',
  'pukau',
  'almas',
  'udara',
  'andan',
  'sedak',
  'pecok',
  'Nobel',
  'binau',
  'tunak',
  'bareh',
  'anani',
  'cekak',
  'bapak',
  'straw',
  'magal',
  'sipat',
  'wisma',
  'titah',
  'jijik',
  'minda',
  'ngilu',
  'kaduk',
  'genih',
  'leper',
  'punca',
  'jajat',
  'naqli',
  'topes',
  'Rasul',
  'kayai',
  'renta',
  'ranai',
  'Korea',
  'sayak',
  'talar',
  'babar',
  'taati',
  'hidap',
  'kusal',
  'wasap',
  'sonet',
  'repes',
  'hasis',
  'ambau',
  'buset',
  'butir',
  'samar',
  'ketup',
  'boron',
  'dekar',
  'gimik',
  'walau',
  'cimni',
  'cedak',
  'kacau',
  'angur',
  'depap',
  'cukur',
  'latur',
  'babon',
  'cakus',
  'pusta',
  'jujat',
  'pecak',
  'kasti',
  'Bajau',
  'tekap',
  'rahat',
  'tebas',
  'tahan',
  'derun',
  'pijak',
  'jitah',
  'lirik',
  'indah',
  'kanda',
  'hidro',
  'galir',
  'suruh',
  'kebel',
  'kuasa',
  'kilik',
  'ambek',
  'alang',
  'dokok',
  'lepih',
  'rulus',
  'lekam',
  'kawat',
  'repih',
  'Rejab',
  'lipit',
  'badan',
  'kekat',
  'garuk',
  'pahar',
  'tifus',
  'segau',
  'tetal',
  'kilap',
  'pudar',
  'unjam',
  'senai',
  'bijih',
  'amali',
  'kecap',
  'perap',
  'cakuk',
  'panel',
  'kawal',
  'belus',
  'polio',
  'redan',
  'aurat',
  'kejar',
  'sedat',
  'kuala',
  'slaid',
  'sitak',
  'dukun',
  'tanda',
  'mogok',
  'sokom',
  'unduk',
  'empis',
  'balur',
  'kejal',
  'jirus',
  'abdas',
  'debuk',
  'pakis',
  'jabun',
  'botol',
  'berup',
  'salib',
  'Tunku',
  'abung',
  'kudai',
  'balau',
  'telus',
  'kalam',
  'betat',
  'gisar',
  'ading',
  'lopek',
  'lecah',
  'kapal',
  'rubah',
  'teper',
  'tuaan',
  'sidai',
  'sisip',
  'kokot',
  'sanak',
  'boyan',
  'gayut',
  'lipis',
  'endah',
  'harus',
  'sarai',
  'buras',
  'pesai',
  'waris',
  'gabak',
  'wisel',
  'kuini',
  'kaset',
  'sobok',
  'jelur',
  'jamba',
  'kulai',
  'camat',
  'pasir',
  'gagal',
  'totok',
  'larva',
  'kipas',
  'sedia',
  'leler',
  'kanta',
  'ketut',
  'temui',
  'julai',
  'sendu',
  'kabus',
  'kelok',
  'pasat',
  'lodoh',
  'lacak',
  'dosen',
  'kasab',
  'redup',
  'hisap',
  'sekut',
  'leher',
  'rusuh',
  'kejat',
  'antul',
  'getar',
  'pasti',
  'burit',
  'cucup',
  'amati',
  'sarwa',
  'tebah',
  'juala',
  'ajnas',
  'sabda',
  'kolum',
  'empar',
  'merdu',
  'jazam',
  'rolet',
  'kelap',
  'derut',
  'pipih',
  'beban',
  'rebek',
  'kojoh',
  'kepuk',
  'lasak',
  'karap',
  'butek',
  'ambur',
  'kaung',
  'bagih',
  'April',
  'pacih',
  'tenun',
  'jilid',
  'samak',
  'kurun',
  'gorak',
  'benah',
  'gepuk',
  'sorok',
  'sedah',
  'kekau',
  'sikin',
  'jaloi',
  'kampa',
  'harum',
  'rejan',
  'gulir',
  'manda',
  'pupus',
  'syair',
  'camau',
  'kecur',
  'anjir',
  'fonem',
  'salbi',
  'najis',
  'jiwit',
  'ondoh',
  'larap',
  'kasta',
  'pundi',
  'pisat',
  'bebek',
  'jagat',
  'jahit',
  'sebal',
  'budek',
  'undan',
  'sumpu',
  'lekat',
  'togel',
  'akuan',
  'jejer',
  'ruyup',
  'sukat',
  'lumai',
  'raksi',
  'sedut',
  'gugur',
  'taala',
  'antep',
  'ligas',
  'kemas',
  'rayon',
  'likas',
  'wajik',
  'nyiur',
  'lisut',
  'acara',
  'wangi',
  'damar',
  'derup',
  'seada',
  'stoma',
  'sesak',
  'sahan',
  'pipit',
  'degil',
  'cerca',
  'Asean',
  'kebus',
  'palai',
  'lapuk',
  'gegat',
  'sepai',
  'pilus',
  'basil',
  'goyak',
  'jidur',
  'ketua',
  'gerut',
  'hafaz',
  'pukat',
  'guruh',
  'bocor',
  'pikat',
  'kedih',
  'lolak',
  'paksa',
  'cabul',
  'kupur',
  'sukan',
  'dubuk',
  'akbar',
  'Johor',
  'kajai',
  'kirip',
  'burun',
  'aspek',
  'arked',
  'gogok',
  'amril',
  'lelan',
  'macam',
  'lupuk',
  'laras',
  'kepal',
  'nilon',
  'galei',
  'birai',
  'belas',
  'mawas',
  'taris',
  'borok',
  'sajen',
  'paluh',
  'sibuk',
  'bekam',
  'lecek',
  'kulak',
  'tekam',
  'amben',
  'hebat',
  'madam',
  'undil',
  'lorah',
  'kamat',
  'tekak',
  'digit',
  'seluk',
  'makna',
  'rurut',
  'kebat',
  'salai',
  'celik',
  'ragus',
  'hirap',
  'tagih',
  'lecap',
  'karim',
  'hiput',
  'tulus',
  'wayar',
  'takma',
  'ngeri',
  'romba',
  'cabai',
  'dungu',
  'becar',
  'ambin',
  'bayan',
  'sedap',
  'lakon',
  'sunyi',
  'awasi',
  'petap',
  'murid',
  'dinda',
  'paris',
  'jalan',
  'rutup',
  'raden',
  'kebun',
  'sigar',
  'rawat',
  'ansur',
  'pasca',
  'cemat',
  'mambu',
  'heret',
  'zalim',
  'bacut',
  'puyuh',
  'hamun',
  'bakai',
  'cemar',
  'kisah',
  'pupal',
  'tugas',
  'disko',
  'kapas',
  'gerun',
  'soseh',
  'tahap',
  'kakek',
  'watas',
  'boyas',
  'diari',
  'mulur',
  'suguh',
  'joule',
  'karaf',
  'gerup',
  'legum',
  'tipus',
  'ancak',
  'nilam',
  'wushu',
  'jejal',
  'melur',
  'beram',
  'pihak',
  'zigot',
  'kaksa',
  'lucah',
  'cukai',
  'puaka',
  'balai',
  'kabir',
  'tagar',
  'ganal',
  'tapir',
  'siwak',
  'oknum',
  'sagar',
  'lekuk',
  'gamam',
  'peper',
  'garpu',
  'runti',
  'mayam',
  'beras',
  'gundi',
  'ricau',
  'ampek',
  'ambak',
  'kahin',
  'kepuh',
  'ofset',
  'tabun',
  'gapai',
  'kepot',
  'kanal',
  'absen',
  'boyak',
  'tamar',
  'banji',
  'debak',
  'parap',
  'darab',
  'hunus',
  'topak',
  'gebar',
  'gubah',
  'bunga',
  'aedes',
  'edisi',
  'klise',
  'lebih',
  'rebut',
  'lapar',
  'bakit',
  'kayak',
  'pegal',
  'ingus',
  'waham',
  'amput',
  'purba',
  'aksen',
  'decit',
  'telap',
  'muzik',
  'pawah',
  'botoh',
  'minit',
  'delan',
  'lonan',
  'cacak',
  'leter',
  'patuh',
  'layah',
  'ungam',
  'buntu',
  'napan',
  'pulas',
  'wahai',
  'selam',
  'jerih',
  'kerai',
  'setat',
  'kasak',
  'kenit',
  'sakal',
  'megun',
  'orang',
  'langu',
  'tulat',
  'denda',
  'bardi',
  'lupat',
  'ulang',
  'capuk',
  'torek',
  'perah',
  'samun',
  'koloh',
  'cagar',
  'troli',
  'kapak',
  'penyu',
  'jagur',
  'jelah',
  'carah',
  'koyam',
  'ekori',
  'sahut',
  'baham',
  'tesak',
  'dekut',
  'gatal',
  'nenas',
  'remos',
  'cempa',
  'ketam',
  'lumbu',
  'kocek',
  'tulah',
  'Aries',
  'kasut',
  'ambar',
  'cuaca',
  'silap',
  'utara',
  'jerut',
  'mesin',
  'tepik',
  'duduk',
  'mohor',
  'keras',
  'tauhu',
  'beset',
  'lacur',
  'satwa',
  'kesot',
  'samar',
  'cipta',
  'pulpa',
  'pukah',
  'karil',
  'labah',
  'silam',
  'nikel',
  'ladan',
  'koyak',
  'gugah',
  'bawat',
  'cakar',
  'hanya',
  'wadah',
  'rekod',
  'kapar',
  'rawit',
  'klien',
  'tebat',
  'pegoh',
  'imbal',
  'jisim',
  'gayal',
  'disah',
  'rusuk',
  'gaduh',
  'cedas',
  'tolak',
  'wadih',
  'sulur',
  'anter',
  'buluh',
  'tirus',
  'begak',
  'sepat',
  'lapis',
  'moyok',
  'komen',
  'iblis',
  'pasuk',
  'lepuh',
  'reluh',
  'kukup',
  'pakem',
  'upiah',
  'macan',
  'dalil',
  'cawis',
  'kuran',
  'tinju',
  'korup',
  'bewok',
  'jumpa',
  'suria',
  'pupuh',
  'rawak',
  'girap',
  'jemah',
  'bagor',
  'asbak',
  'beiri',
  'kilat',
  'cabir',
  'beser',
  'abadi',
  'sopan',
  'embat',
  'retas',
  'kanan',
  'bulai',
  'endal',
  'anyur',
  'golek',
  'katil',
  'tolol',
  'jejeh',
  'cebir',
  'kiras',
  'elang',
  'atawa',
  'ledak',
  'hemat',
  'adili',
  'lelas',
  'jebat',
  'regis',
  'jubah',
  'kocoh',
  'dalca',
  'tiung',
  'pelak',
  'apion',
  'hirup',
  'hulur',
  'pesak',
  'luruh',
  'kaloi',
  'keluh',
  'cacau',
  'merta',
  'abras',
  'lekis',
  'susup',
  'kolak',
  'lambu',
  'wajib',
  'siren',
  'sasap',
  'pasah',
  'letas',
  'cabur',
  'mikro',
  'onyok',
  'pucuk',
  'bujur',
  'bilau',
  'lotek',
  'silah',
  'sejuk',
  'rapah',
  'sakur',
  'celuk',
  'cemer',
  'kecai',
  'sirap',
  'gelap',
  'ehwal',
  'murni',
  'kumal',
  'bagat',
  'lepas',
  'terap',
  'korum',
  'tegak',
  'nisbi',
  'tutup',
  'islam',
  'lunak',
  'turap',
  'kempu',
  'encal',
  'lemak',
  'belot',
  'kelun',
  'kalih',
  'surut',
  'batil',
  'lasah',
  'oleng',
  'tunda',
  'raksa',
  'cabau',
  'tidak',
  'milik',
  'beksa',
  'ubati',
  'silat',
  'aktif',
  'kalbu',
  'larih',
  'lalai',
  'durja',
  'fobia',
  'gerit',
  'resit',
  'derak',
  'sakat',
  'kulan',
  'kusam',
  'kepam',
  'layam',
  'anfas',
  'anjur',
  'lulut',
  'tepat',
  'dosej',
  'rajut',
  'nahas',
  'balak',
  'endul',
  'keweh',
  'bujam',
  'kusta',
  'hadis',
  'kalis',
  'dalih',
  'bibir',
  'ulser',
  'desuk',
  'lecet',
  'ketik',
  'robek',
  'hamil',
  'garap',
  'tuduh',
  'saraf',
  'buang',
  'ceguk',
  'ultra',
  'jalar',
  'lanca',
  'selak',
  'garut',
  'manda',
  'gosip',
  'malas',
  'decup',
  'suaka',
  'atlit',
  'pujut',
  'hiris',
  'kejap',
  'caram',
  'sauna',
  'honar',
  'rafia',
  'sanad',
  'kabak',
  'berok',
  'penuh',
  'kapur',
  'benua',
  'beduk',
  'gorok',
  'tukik',
  'kepak',
  'album',
  'gerak',
  'empap',
  'tarum',
  'sawat',
  'iklan',
  'rusam',
  'Raden',
  'kalak',
  'ranca',
  'pulau',
  'luyak',
  'deret',
  'egois',
  'katut',
  'topek',
  'bayar',
  'busuk',
  'papas',
  'caran',
  'giwah',
  'kempa',
  'bidal',
  'gandu',
  'bakau',
  'tohor',
  'darai',
  'biara',
  'dokar',
  'gelek',
  'orkid',
  'harta',
  'kuota',
  'podak',
  'kupon',
  'camar',
  'lokan',
  'lokek',
  'pulut',
  'heban',
  'paduk',
  'batal',
  'kunci',
  'calai',
  'pecut',
  'rajin',
  'tepus',
  'kujur',
  'lebai',
  'pugar',
  'aksep',
  'pleno',
  'patih',
  'tojok',
  'dukuh',
  'warta',
  'puisi',
  'terok',
  'dipan',
  'biaya',
  'ihram',
  'nisab',
  'salah',
  'kutip',
  'tawas',
  'kulau',
  'celup',
  'buruh',
  'antar',
  'labas',
  'alias',
  'barik',
  'kopak',
  'jabar',
  'pialu',
  'cebur',
  'golok',
  'terup',
  'anduh',
  'lebam',
  'radup',
  'depot',
  'cenda',
  'dolar',
  'skala',
  'relas',
  'ketum',
  'jimak',
  'suram',
  'tasak',
  'teluk',
  'paltu',
  'nazir',
  'baguk',
  'kumin',
  'bagai',
  'tekan',
  'bokor',
  'korek',
  'kekar',
  'colak',
  'usali',
  'jelau',
  'catan',
  'tenak',
  'gawal',
  'badar',
  'babuk',
  'wuquf',
  'susui',
  'bodor',
  'deban',
  'bagau',
  'sebat',
  'kesan',
  'jibun',
  'jabat',
  'babal',
  'bereo',
  'layak',
  'layuk',
  'samas',
  'babad',
  'ahkam',
  'kelik',
  'andar',
  'kuali',
  'rambu',
  'sigap',
  'sagun',
  'bubul',
  'kroni',
  'mulas',
  'pekik',
  'subal',
  'anyal',
  'motor',
  'sahur',
  'abuan',
  'perca',
  'degap',
  'borat',
  'jiwat',
  'tawar',
  'nifas',
  'totet',
  'jamiz',
  'desau',
  'makam',
  'lokar',
  'gaguk',
  'becok',
  'prosa',
  'lihat',
  'kesip',
  'rajau',
  'adres',
  'bubut',
  'anang',
  'redha',
  'azali',
  'lajur',
  'atung',
  'haram',
  'loban',
  'gerus',
  'biasa',
  'amaun',
  'kerau',
  'mahal',
  'perlu',
  'luhur',
  'kuras',
  'bapet',
  'kelab',
  'dedah',
  'limau',
  'dekit',
  'lapah',
  'mewah',
  'jetis',
  'debam',
  'aroma',
  'Timur',
  'panah',
  'gugat',
  'bunyi',
  'napal',
  'empuk',
  'unjur',
  'bilur',
  'ambal',
  'lumur',
  'abuya',
  'parut',
  'pagai',
  'fauna',
  'limpa',
  'batau',
  'lamat',
  'padat',
  'telah',
  'versi',
  'luluh',
  'tedas',
  'bunuh',
  'bindu',
  'dawah',
  'lecak',
  'bacuk',
  'berit',
  'besut',
  'liang',
  'nenes',
  'sakit',
  'gilir',
  'benda',
  'payau',
  'merak',
  'sifir',
  'darat',
  'nusus',
  'rumus',
  'laung',
  'daras',
  'suasa',
  'asbut',
  'anjal',
  'dakap',
  'untai',
  'pinta',
  'lumas',
  'tatal',
  'keria',
  'siang',
  'kamit',
  'temak',
  'saruk',
  'zaman',
  'lurah',
  'lumat',
  'palsu',
  'erong',
  'kedek',
  'kikir',
  'antri',
  'jihat',
  'biras',
  'jaguh',
  'renut',
  'garaj',
  'perut',
  'asrar',
  'singa',
  'bujal',
  'celus',
  'sarat',
  'godak',
  'opera',
  'camuk',
  'gamat',
  'dekak',
  'locak',
  'wilis',
  'rapih',
  'perat',
  'kunca',
  'satir',
  'dugal',
  'relai',
  'sirip',
  'sains',
  'jeluk',
  'ketua',
  'didih',
  'bekat',
  'semut',
  'gedek',
  'bedel',
  'ganas',
  'kendi',
  'pisah',
  'rapuh',
  'borci',
  'fasal',
  'misai',
  'derit',
  'litak',
  'empul',
  'lerap',
  'lemuk',
  'baldu',
  'pejal',
  'randi',
  'mercu',
  'bekil',
  'hapus',
  'impak',
  'piang',
  'mulia',
  'jadam',
  'tuang',
  'jahat',
  'bubun',
  'gabai',
  'ghair',
  'rabak',
  'kipai',
  'baton',
  'abses',
  'nipah',
  'fenol',
  'folio',
  'rabas',
  'ialah',
  'belut',
  'wujud',
  'antah',
  'lelah',
  'duyun',
  'novis',
  'tairu',
  'oasis',
  'golbi',
  'resan',
  'gelar',
  'barah',
  'luang',
  'remah',
  'sumbu',
  'guris',
  'letap',
  'tekun',
  'korus',
  'asbab',
  'tunas',
  'argus',
  'polos',
  'rimis',
  'risak',
  'hakis',
  'lukis',
  'harak',
  'lilin',
  'aning',
  'kerik',
  'petut',
  'pahit',
  'bayam',
  'benak',
  'bison',
  'motel',
  'litar',
  'botor',
  'kawah',
  'lerah',
  'usung',
  'cupak',
  'geruk',
  'tepok',
  'sepuk',
  'gerai',
  'burka',
  'muram',
  'jarab',
  'pulai',
  'rifel',
  'lalui',
  'rimbu',
  'apati',
  'orkes',
  'rimau',
  'cadak',
  'model',
  'unjap',
  'jakal',
  'camca',
  'lepak',
  'tasai',
  'malah',
  'jajpa',
  'abrit',
  'rawah',
  'final',
  'lilan',
  'cenak',
  'jatik',
  'delta',
  'kargo',
  'teksi',
  'baken',
  'incut',
  'tubul',
  'apnea',
  'radar',
  'cemuk',
  'fetus',
  'riyak',
  'pagut',
  'pakma',
  'dabik',
  'camur',
  'boyot',
  'drama',
  'cecap',
  'sanga',
  'sebab',
  'sedan',
  'ondok',
  'sivil',
  'umpan',
  'sadur',
  'basah',
  'colek',
  'talan',
  'musim',
  'garan',
  'Artik',
  'jidal',
  'dupak',
  'kedah',
  'pojok',
  'fikir',
  'getah',
  'butal',
  'datuk',
  'konco',
  'teguh',
  'jirak',
  'jalar',
  'togan',
  'enzim',
  'tante',
  'kepur',
  'CDROM',
  'setel',
  'acang',
  'buyar',
  'kelus',
  'tukas',
  'pesat',
  'teras',
  'cerek',
  'baron',
  'waras',
  'lerik',
  'ajeng',
  'redih',
  'lakur',
  'dikit',
  'pakuh',
  'gocoh',
  'kolek',
  'umbut',
  'kusau',
  'sekap',
  'pakar',
  'kacir',
  'kibar',
  'kukuh',
  'gegar',
  'kenas',
  'lagam',
  'berus',
  'ebeng',
  'mudik',
  'patar',
  'tanya',
  'runut',
  'kilas',
  'tepas',
  'genit',
  'sinau',
  'taruh',
  'niaga',
  'lebir',
  'sakti',
  'gogah',
  'amboi',
  'temas',
  'petai',
  'asasi',
  'gensi',
  'ratah',
  'sakan',
  'jentu',
  'repuh',
  'belia',
  'boreh',
  'nisan',
  'hilau',
  'pinga',
  'dekih',
  'uling',
  'latah',
  'halai',
  'ceria',
  'gapis',
  'obral',
  'rawon',
  'melut',
  'hamis',
  'skuad',
  'luyut',
  'pakan',
  'mahir',
  'lepus',
  'repet',
  'penai',
  'redip',
  'beles',
  'kacar',
  'keroh',
  'jelas',
  'bilah',
  'sampu',
  'ungar',
  'larak',
  'nista',
  'tajam',
  'herba',
  'Batin',
  'belir',
  'sorek',
  'atase',
  'lompi',
  'getis',
  'bulan',
  'sabdu',
  'derus',
  'cebok',
  'wasit',
  'jilat',
  'doyak',
  'lupai',
  'muara',
  'tokek',
  'jujuk',
  'ragin',
  'rujah',
  'bidis',
  'wawas',
  'pucat',
  'skima',
  'mukah',
  'Plato',
  'susuk',
  'terum',
  'kilau',
  'landa',
  'legup',
  'pesek',
  'golak',
  'sigai',
  'gawat',
  'tusam',
  'betet',
  'sarut',
  'gayam',
  'pumis',
  'ancoa',
  'dogel',
  'asfal',
  'sedik',
  'sebit',
  'objek',
  'kulat',
  'bijak',
  'subam',
  'cadar',
  'jalir',
  'debap',
  'cawan',
  'modul',
  'moyot',
  'pinar',
  'susun',
  'jagal',
  'copet',
  'ajung',
  'agama',
  'bakak',
  'bakam',
  'ilahi',
  'catuk',
  'ingin',
  'rebus',
  'skrip',
  'umbar',
  'sulit',
  'nasab',
  'bengu',
  'lilau',
  'tetap',
  'kelam',
  'betis',
  'soket',
  'bakar',
  'nambi',
  'pergi',
  'pecai',
  'Virgo',
  'ateis',
  'kadet',
  'antik',
  'kelih',
  'redah',
  'sitih',
  'harga',
  'minta',
  'surin',
  'kayan',
  'audit',
  'kecah',
  'roneo',
  'andak',
  'Arasy',
  'turas',
  'sunat',
  'sampa',
  'bakir',
  'darul',
  'capik',
  'liput',
  'hajah',
  'telor',
  'sirna',
  'parih',
  'pagar',
  'tusuk',
  'firus',
  'injap',
  'tabir',
  'praja',
  'burai',
  'nonok',
  'gurur',
  'demis',
  'udang',
  'teman',
  'julap',
  'rangu',
  'rabbi',
  'akhir',
  'kejam',
  'sejak',
  'rabuk',
  'tatah',
  'telur',
  'katar',
  'patok',
  'malap',
  'begar',
  'imbuh',
  'pulun',
  'galuh',
  'tanih',
  'Parsi',
  'lampu',
  'kenal',
  'deria',
  'mihun',
  'bular',
  'mirip',
  'solek',
  'cekal',
  'pikul',
  'buari',
  'pamah',
  'kusum',
  'kacak',
  'bebar',
  'kapan',
  'bokca',
  'lasuh',
  'tolap',
  'sujud',
  'betul',
  'ponok',
  'sabuk',
  'syiar',
  'cekik',
  'palau',
  'takuh',
  'wazir',
  'lipat',
  'hujan',
  'caing',
  'faham',
  'turbo',
  'jenis',
  'hadat',
  'bilik',
  'cicir',
  'pacul',
  'gaung',
  'gotes',
  'sunah',
  'cukin',
  'detap',
  'kerbu',
  'resik',
  'tanak',
  'kedip',
  'kecil',
  'biadi',
  'andai',
  'kaing',
  'nafsu',
  'ketan',
  'lesen',
  'tiris',
  'vokal',
  'zuhud',
  'Kyoto',
  'gubas',
  'labak',
  'bursa',
  'ambat',
  'rakap',
  'jerit',
  'lagut',
  'ropol',
  'petis',
  'tiruk',
  'surau',
  'siram',
  'antup',
  'mesiu',
  'hatta',
  'bakap',
  'basau',
  'ngelu',
  'helai',
  'bolai',
  'putut',
  'jinak',
  'ratus',
  'unsur',
  'rapus',
  'lesut',
  'gigih',
  'kinja',
  'tekah',
  'apung',
  'seman',
  'libat',
  'jebak',
  'kemik',
  'padah',
  'pekak',
  'laici',
  'cinta',
  'jujuh',
  'umpak',
  'salih',
  'teyan',
  'arbab',
  'wahid',
  'endap',
  'gegau',
  'kalau',
  'malar',
  'rodak',
  'panik',
  'afiat',
  'fomen',
  'judul',
  'timur',
  'hajat',
  'cerna',
  'sakai',
  'darah',
  'solat',
  'sawar',
  'makan',
  'gutik',
  'resap',
  'dansa',
  'labuh',
  'kurus',
  'embak',
  'belar',
  'rapun',
  'sebak',
  'kayuh',
  'sorak',
  'kitab',
  'sawai',
  'katak',
  'bakup',
  'tuhan',
  'papak',
  'sasul',
  'candu',
  'kacip',
  'cekam',
  'qadar',
  'idami',
  'beloh',
  'ludai',
  'banuk',
  'gerik',
  'tepur',
  'diada',
  'kirap',
  'lekuh',
  'ramah',
  'kesal',
  'bakik',
  'larik',
  'lebar',
  'jatah',
  'lemba',
  'gomol',
  'kelui',
  'label',
  'desur',
  'mamah',
  'punai',
  'andas',
  'pensi',
  'kudis',
  'dikau',
  'kolot',
  'subur',
  'dodoi',
  'tabur',
  'sikap',
  'batis',
  'tubin',
  'baruh',
  'nafsi',
  'virus',
  'mayor',
  'bebel',
  'dohok',
  'bemak',
  'baluh',
  'topik',
  'lemau',
  'skuba',
  'pupuk',
  'sugun',
  'supir',
  'zahir',
  'satah',
  'kilan',
  'vilus',
  'besek',
  'pikau',
  'kokol',
  'omega',
  'sapta',
  'gigil',
  'juzuk',
  'sagur',
  'cempo',
  'Safar',
  'mesra',
  'bordu',
  'intip',
  'Sabah',
  'begol',
  'umrah',
  'lakum',
  'berat',
  'ligih',
  'risik',
  'bidan',
  'legih',
  'depak',
  'nyana',
  'buaya',
  'pahuk',
  'busut',
  'gurau',
  'rabut',
  'sabil',
  'lanus',
  'berko',
  'kubur',
  'rasan',
  'diksa',
  'rogol',
  'bubus',
  'jajar',
  'besen',
  'banta',
  'afdal',
  'binal',
  'kerja',
  'Megat',
  'jagai',
  'enjut',
  'regek',
  'cerah',
  'serai',
  'lokus',
  'naung',
  'moral',
  'galas',
  'adpis',
  'lusuh',
  'nadir',
  'repas',
  'buret',
  'helat',
  'petus',
  'bilit',
  'kepit',
  'kupas',
  'juara',
  'Ungku',
  'sadau',
  'badai',
  'akrab',
  'hadap',
  'bobos',
  'hadir',
  'varia',
  'serap',
  'marah',
  'hindu',
  'buhuk',
  'ungti',
  'masai',
  'jawab',
  'depus',
  'gogol',
  'cecak',
  'kirau',
  'isbat',
  'rotor',
  'gayuh',
  'tikas',
  'cekar',
  'jelum',
  'baduk',
  'pitam',
  'kisit',
  'pakej',
  'aduan',
  'dasar',
  'hakam',
  'induk',
  'Santa',
  'kerup',
  'poket',
  'watan',
  'kodok',
  'selip',
  'mawar',
  'kalah',
  'palen',
  'sikah',
  'halia',
  'luluk',
  'moden',
  'jasad',
  'togok',
  'denai',
  'andun',
  'ombak',
  'gemal',
  'cicak',
  'gitan',
  'Zabur',
  'jejak',
  'hibur',
  'enjak',
  'benih',
  'punya',
  'tilik',
  'risau',
  'tikpi',
  'modus',
  'halal',
  'lamar',
  'kepis',
  'canai',
  'sitar',
  'pekat',
  'untut',
  'semir',
  'vivid',
  'sunam',
  'jujut',
  'kedap',
  'jerah',
  'imbas',
  'gurih',
  'belon',
  'qidam',
  'pesan',
  'keluk',
  'ceruk',
  'suang',
  'hasil',
  'tular',
  'jiwai',
  'beton',
  'siput',
  'lurik',
  'dodol',
  'toyak',
  'bekah',
  'kuyuk',
  'balon',
  'jejas',
  'tobji',
  'torak',
  'benum',
  'cemas',
  'lanau',
  'ropak',
  'hukka',
  'pucik',
  'butuh',
  'cebeh',
  'bufet',
  'gagai',
  'basal',
  'yuran',
  'pekis',
  'barau',
  'kisai',
  'sarau',
  'tekad',
  'beres',
  'padau',
  'arbaa',
  'satar',
  'kabel',
  'habuk',
  'letik',
  'ketap',
  'bibit',
  'sabit',
  'jalad',
  'antui',
  'imlek',
  'labut',
  'petir',
  'Jebat',
  'leban',
  'toleh',
  'inang',
  'dadah',
  'kenya',
  'pereh',
  'simen',
  'birih',
  'kelir',
  'sekeh',
  'kafir',
  'abang',
  'tobak',
  'bokek',
  'gurah',
  'beker',
  'layuh',
  'kabil',
  'murba',
  'romok',
  'celau',
  'depun',
  'kerki',
  'waima',
  'oktaf',
  'uskup',
  'hasut',
  'falak',
  'canar',
  'remeh',
  'desit',
  'kalai',
  'insan',
  'beret',
  'lagun',
  'ampuh',
  'sabur',
  'taban',
  'gadis',
  'gopoh',
  'gilis',
  'cabut',
  'ceduk',
  'romen',
  'kenan',
  'gugus',
  'losen',
  'mobil',
  'Quran',
  'kutik',
  'lanun',
  'ujang',
  'samir',
  'desar',
  'bogel',
  'sipai',
  'lotah',
  'dunak',
  'dubur',
  'masin',
  'tiram',
  'lidas',
  'jirai',
  'setir',
  'Kuala',
  'Mamak',
  'magma',
  'tipis',
  'jerau',
  'iklim',
  'libut',
  'tapin',
  'parsi',
  'bilak',
  'capai',
  'palat',
  'celat',
  'gegai',
  'tetak',
  'asang',
  'lupuh',
  'beria',
  'celar',
  'baksi',
  'sunan',
  'bikir',
  'gigau',
  'ceper',
  'imsak',
  'cekap',
  'kasip',
  'suara',
  'bidai',
  'salir',
  'rehal',
  'mapan',
  'danur',
  'dabus',
  'wasak',
  'kepet',
  'belit',
  'kiani',
  'vulva',
  'jeruk',
  'hakim',
  'salut',
  'unjut',
  'janda',
  'tebus',
  'zirah',
  'sepoi',
  'bobek',
  'bisul',
  'tukil',
  'kutil',
  'tatap',
  'locok',
  'godok',
  'cakap',
  'kicau',
  'sinis',
  'hobat',
  'ahram',
  'hudud',
  'beroi',
  'rewel',
  'giras',
  'budul',
  'popok',
  'fasis',
  'dakwa',
  'keong',
  'gisal',
  'resah',
  'uring',
  'madya',
  'putih',
  'nikah',
  'toyoh',
  'sadis',
  'sobek',
  'jawat',
  'parak',
  'kelum',
  'lepek',
  'sepit',
  'tambi',
  'selut',
  'pakai',
  'redap',
  'patuk',
  'gorek',
  'tikar',
  'kedua',
  'sukar',
  'tahir',
  'gobar',
  'gelap',
  'jersi',
  'jabir',
  'gunci',
  'gulat',
  'patah',
  'rekah',
  'fosil',
  'godek',
  'lipan',
  'damak',
  'latak',
  'betak',
  'sarak',
  'ketur',
  'catut',
  'saban',
  'lelar',
  'nyuar',
  'salji',
  'cocok',
  'setia',
  'sorai',
  'deram',
  'kemut',
  'benta',
  'tajur',
  'senda',
  'ulama',
  'sonor',
  'hilir',
  'cucuh',
  'jaram',
  'kelah',
  'manik',
  'kondo',
  'jagak',
  'akaun',
  'irama',
  'ulung',
  'lipas',
  'cegak',
  'lemas',
  'kredo',
  'kaboi',
  'serut',
  'ramus',
  'gegak',
  'pacau',
  'kibir',
  'sayid',
  'korok',
  'sanya',
  'cekit',
  'rabit',
  'Syiah',
  'ranum',
  'bayak',
  'kizib',
  'sunti',
  'belau',
  'stail',
  'keret',
  'gelut',
  'relap',
  'samai',
  'ampel',
  'rajan',
  'birah',
  'kejut',
  'jenak',
  'petas',
  'samba',
  'wabak',
  'cabik',
  'norma',
  'lopes',
  'temeh',
  'larau',
  'pepai',
  'raban',
  'nyawa',
  'padma',
  'toman',
  'elegi',
  'andur',
  'gilau',
  'polis',
  'geser',
  'ahmak',
  'bosan',
  'magis',
  'sayat',
  'pandu',
  'leram',
  'tagan',
  'bujuk',
  'celek',
  'tatih',
  'angut',
  'bales',
  'kasih',
  'tanpa',
  'derum',
  'tegah',
  'latam',
  'titis',
  'tepis',
  'dedak',
  'gerip',
  'telek',
  'afrit',
  'suwir',
  'pukal',
  'kubal',
  'larut',
  'tipai',
  'ironi',
  'sunai',
  'samum',
  'royal',
  'makin',
  'rutuk',
  'sumur',
  'halba',
  'putat',
  'angga',
  'becah',
  'polek',
  'cucur',
  'hamam',
  'rumit',
  'liris',
  'kabul',
  'sekah',
  'taiso',
  'surih',
  'halwa',
  'bukan',
  'lebat',
  'bekuk',
  'dinar',
  'bulur',
  'jebik',
  'ayeng',
  'tabla',
  'ruing',
  'paten',
  'katih',
  'remit',
  'batai',
  'dayak',
  'rosan',
  'desir',
  'kasai',
  'tukar',
  'bomoh',
  'bijan',
  'rosot',
  'remba',
  'gilap',
  'kebut',
  'intai',
  'lelai',
  'alami',
  'aneka',
  'rojak',
  'labur',
  'lahir',
  'tunam',
  'lapor',
  'kijil',
  'patik',
  'patin',
  'piara',
  'sabar',
  'kapit',
  'bosor',
  'bubar',
  'robak',
  'luput',
  'ghali',
  'tanin',
  'rabun',
  'Sakai',
  'memek',
  'gayau',
  'carut',
  'taruk',
  'ceber',
  'retin',
  'bawah',
  'yakni',
  'bebas',
  'kasir',
  'bedal',
  'sogok',
  'sibur',
  'Sabtu',
  'pudat',
  'murka',
  'ayang',
  'ampun',
  'dikir',
  'panca',
  'latuh',
  'lukat',
  'rawai',
  'jadah',
  'kasus',
  'debit',
  'bidas',
  'gitar',
  'besel',
  'rakus',
  'larat',
  'barat',
  'salin',
  'Agong',
  'susul',
  'tegar',
  'rapai',
  'lembu',
  'jenut',
  'basuk',
  'rinci',
  'debuk',
  'kanak',
  'limut',
  'malai',
  'ketel',
  'kolej',
  'cagut',
  'getir',
  'cahar',
  'siluk',
  'sekul',
  'ovari',
  'desas',
  'mabur',
  'rejeh',
  'kelek',
  'jihad',
  'sisih',
  'ranap',
  'habib',
  'abaka',
  'segak',
  'sendi',
  'girah',
  'belak',
  'uncak',
  'tawak',
  'tarik',
  'enjal',
  'Barat',
  'paria',
  'imago',
  'unyai',
  'dedap',
  'masya',
  'sesap',
  'tanur',
  'ridan',
  'celih',
  'zakat',
  'banal',
  'tokak',
  'candi',
  'legap',
  'besan',
  'bitis',
  'sebuk',
  'lawas',
  'bakca',
  'pagan',
  'panja',
  'cebis',
  'jarak',
  'bazir',
  'cadas',
  'nenek',
  'beber',
  'babas',
  'puyun',
  'unguh',
  'komun',
  'dolak',
  'suhun',
  'ratap',
  'banci',
  'keroi',
  'racau',
  'jamik',
  'karya',
  'tayum',
  'lalar',
  'babut',
  'zarah',
  'dawai',
  'zabib',
  'radio',
  'debas',
  'rehat',
  'leleh',
  'kobok',
  'Injil',
  'degup',
  'semar',
  'radas',
  'dokoh',
  'bawel',
  'umbuk',
  'halus',
  'parti',
  'bakal',
  'gamit',
  'kamil',
  'rumah',
  'nyata',
  'tulen',
  'sawan',
  'ralip',
  'sutan',
  'kapai',
  'wajah',
  'warna',
  'babit',
  'rosak',
  'lamun',
  'fatwa',
  'alung',
  'kelas',
  'bayas',
  'layut',
  'kurva',
  'tajin',
  'nukil',
  'larah',
  'piket',
  'gayun',
  'ludat',
  'senja',
  'sebun',
  'kuria',
  'forum',
  'novel',
  'subuh',
  'seput',
  'belom',
  'embut',
  'keruh',
  'repak',
  'sagat',
  'puasa',
  'kadut',
  'dalik',
  'jeran',
  'sihat',
  'kabit',
  'balap',
  'susur',
  'perot',
  'Ajami',
  'lecuh',
  'palka',
  'ngira',
  'kucar',
  'sivik',
  'tepak',
  'qunut',
  'purun',
  'kerip',
  'juita',
  'wakaf',
  'belah',
  'tubir',
  'incit',
  'bahas',
  'media',
  'bopet',
  'ragbi',
  'tukul',
  'tepeh',
  'teres',
  'cekur',
  'tiang',
  'antun',
  'jujai',
  'cemus',
  'kukus',
  'masak',
  'geman',
  'cekau',
  'kohol',
  'tilam',
  'becuk',
  'riang',
  'neram',
  'bumbu',
  'lebuk',
  'calit',
  'estet',
  'tubuh',
  'punci',
  'jejap',
  'lukah',
  'puing',
  'ASEAN',
  'rumba',
  'tauci',
  'joker',
  'katir',
  'labun',
  'tadah',
  'nafar',
  'gerau',
  'latuk',
  'kotek',
  'tabak',
  'sutuh',
  'ngian',
  'kerek',
  'bejat',
  'tampa',
  'detak',
  'lejar',
  'jujur',
  'demit',
  'serat',
  'cakna',
  'bebat',
  'lulur',
  'lahad',
  'koala',
  'cebik',
  'pokok',
  'abtar',
  'pelik',
  'ganti',
  'mulus',
  'sosio',
  'hilai',
  'kecut',
  'anyih',
  'bekas',
  'geluk',
  'setui',
  'ikrar',
  'ameba',
  'gemam',
  'jakas',
  'cadur',
  'leces',
  'buana',
  'gosok',
  'nanah',
  'cagak',
  'beluh',
  'kirim',
  'lugas',
  'panas',
  'tidur',
  'damba',
  'jauhi',
  'sirah',
  'kakak',
  'anduk',
  'pacar',
  'tebar',
  'sigun',
  'sawah',
  'pitar',
  'rujuk',
  'motif',
  'kesut',
  'bokeh',
  'suban',
  'karam',
  'aling',
  'kebal',
  'kusir',
  'ketak',
  'tabal',
  'ampit',
  'tegun',
  'arkib',
  'wuduk',
  'cabit',
  'perai',
  'pilas',
  'capek',
  'saput',
  'pahat',
  'tetas',
  'sodek',
  'degum',
  'babun',
  'lapur',
  'hijab',
  'faraj',
  'plaza',
  'halau',
  'bulir',
  'syala',
  'arung',
  'puala',
  'sopak',
  'toaha',
  'buduk',
  'lanji',
  'lahar',
  'beran',
  'poren',
  'selit',
  'burut',
  'dahan',
  'siapa',
  'nilau',
  'tekat',
  'Tamil',
  'batok',
  'tagak',
  'pegat',
  'lensa',
  'napuh',
  'cagun',
  'nenda',
  'tukus',
  'dadak',
  'gagak',
  'baiki',
  'kobar',
  'sikik',
  'masam',
  'lelap',
  'moreh',
  'besot',
  'yunda',
  'incil',
  'deras',
  'sayur',
  'liter',
  'kebah',
  'baung',
  'macis',
  'renga',
  'antat',
  'detup',
  'teduh',
  'jaksa',
  'legar',
  'Tuhan',
  'koboi',
  'jurus',
  'radin',
  'cekut',
  'dengu',
  'bolos',
  'pepet',
  'tepet',
  'antan',
  'ambul',
  'Kulim',
  'jalil',
  'gawar',
  'kasau',
  'ambet',
  'usang',
  'impas',
  'culas',
  'palas',
  'empat',
  'ujana',
  'cicip',
  'kayau',
  'poros',
  'gusar',
  'hayat',
  'gusti',
  'ketus',
  'humus',
  'royak',
  'kekel',
  'ikrab',
  'rupal',
  'titip',
  'dekan',
  'jubli',
  'luing',
  'senam',
  'remis',
  'Kedah',
  'ruang',
  'boroi',
  'lukih',
  'bilas',
  'lesak',
  'tahun',
  'tempo',
  'panen',
  'yogia',
  'gamba',
  'ambon',
  'sahih',
  'hiruk',
  'ampul',
  'komoi',
  'manis',
  'selum',
  'bubuk',
  'katup',
  'likuh',
  'semat',
  'bedil',
  'sadai',
  'batin',
  'pelir',
  'denah',
  'terak',
  'butul',
  'rubin',
  'lumer',
  'Kudat',
  'tupai',
  'bobok',
  'fajar',
  'campa',
  'robok',
  'mafia',
  'upaya',
  'bayur',
  'lepur',
  'racun',
  'angsa',
  'gabah',
  'lecok',
  'resam',
  'pedap',
  'gempa',
  'rumbu',
  'bukat',
  'ritau',
  'recik',
  'nyala',
  'nafas',
  'solak',
  'taucu',
  'kambi',
  'paras',
  'casis',
  'demam',
  'bekal',
  'benet',
  'kawik',
  'jojol',
  'lilis',
  'calik',
  'telan',
  'bogam',
  'koman',
  'lepik',
  'lokap',
  'catat',
  'bacak',
  'rebab',
  'kicar',
  'barua',
  'piung',
  'lemek',
  'letus',
  'oncom',
  'samah',
  'sekoi',
  'uktab',
  'batuk',
  'takik',
  'putik',
  'kelar',
  'angan',
  'China',
  'latih',
  'ceras',
  'kekal',
  'bajau',
  'skrum',
  'cekah',
  'boceh',
  'kebuk',
  'silau',
  'bekak',
  'iodin',
  'gabuk',
  'awang',
  'mukim',
  'balui',
  'lenja',
  'dusun',
  'darun',
  'titar',
  'lecit',
  'lepoh',
  'kaper',
  'camik',
  'lilit',
  'pulih',
  'makro',
  'petah',
  'rakan',
  'windu',
  'buram',
  'botox',
  'tebok',
  'begap',
  'sadak',
  'rayap',
  'rukam',
  'lente',
  'saksi',
  'doger',
  'karet',
  'turun',
  'jadar',
  'mudah',
  'gayuk',
  'ratna',
  'temut',
  'seret',
  'sabut',
  'banyu',
  'peram',
  'suhad',
  'kiung',
  'sepuh',
  'Zuhur',
  'cacil',
  'kutub',
  'bazar',
  'derak',
  'wiski',
  'bukut',
  'salam',
  'aurum',
  'kerul',
  'flora',
  'calon',
  'cemek',
  'gumai',
  'sekip',
  'kesuk',
  'tutul',
  'kikis',
  'didal',
  'terma',
  'caung',
  'selap',
  'input',
  'cacar',
  'dewan',
  'cumbu',
  'mulai',
  'pasta',
  'balet',
  'rukun',
  'likir',
  'lesit',
]
